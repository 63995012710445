import React, { FC } from 'react'
import AppsPanel from './AppsPanel'
import NoAppsPanel from './NoAppsPanel'

import { /*Base64SVG,*/ ImgWrapper } from '@sistemiv/s-components'

//import { Application } from '../../model/User'
import { Organization } from '../../model/Organizations'
import { imageBase } from '../../services/http-common'

export type OrgsLIstProps = {
  //applications: Application[]
  organizations: Organization[]
}

const OrgsList: FC<OrgsLIstProps> = ({ organizations }) => {
  return (
    <div>
      {organizations?.map((org, index) => (
        <div key={index} className='flex flex-col items-center mb-14 px-3'>
          <div className='flex flex-row p-3 items-center mb-3 '>
            <div
              className={`w-8 h-8 rounded-full text-white flex items-center justify-center text-lg leading-[18px] border-sky-500 border ${
                organizations?.find((o) => o.organizationCode === org.organizationCode)?.icon ? '' : 'bg-[#7b87ae]'
              }`}
            >
              <ImgWrapper
                className='w-full h-full rounded-full'
                src={`${imageBase}/${org.organizationCode}/76`}
                onErrorComponent={
                  <p>
                    {organizations
                      .find((o) => o.organizationCode === org.organizationCode)
                      ?.name?.charAt(0)
                      ?.toUpperCase()}
                  </p>
                }
              />
            </div>
            <p className='text-[18px] ml-2'>{org.name}</p>
          </div>
          {org.appAccess?.length != 0 ? <AppsPanel org={org} /> : <NoAppsPanel />}
        </div>
      ))}
    </div>
  )
}

export default OrgsList
