import { ImgWrapper, NavigationBar } from '@sistemiv/s-components'
import React, { FC, Fragment, useContext } from 'react'
import { CheckIcon, GlobeAltIcon } from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'
import { AuthContext } from '../../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import AppBaseLogo from '../../assets/AppBaseLogo'
import { useProfile } from '../../repositories/user/user-profile.repository'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'
import { useResponsive } from '../../contexts/ResponsiveContextProvider'

const TopNav: FC = () => {
  const { isMobile } = useResponsive()
  const { account } = useContext(AuthContext)
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { data: user } = useProfile({ userId: account?.localAccountId ?? '' })
  const handleLogout = async () => {
    // const { idToken } = await instance.acquireTokenSilent({
    //   scopes: requestScopes,
    //   account: instance.getActiveAccount() ?? undefined,
    // })
    // instance.logoutRedirect({ account: instance.getActiveAccount(), idTokenHint: idToken })
    navigate('/logout')
  }
  const locales = [
    { value: 'en', title: 'English (en)' },
    { value: 'sr', title: 'Srpski (sr)' },
  ]

  const handleChangeLng = (lng: string) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('lng', lng)
  }

  return (
    <NavigationBar className='h-[4.185rem] border-b border-gray-300 flex items-center justify-between'>
      <div className='flex items-center gap-x-2'>
        <AppBaseLogo />
      </div>
      <div className='flex items-center gap-x-2'>
        {/* <NotificationsMenuItem
          unreadNotifications={3}
          labels={{
            notifications: 'Notifications',
            show_unread: 'Show unread',
            mark_all_as_read: 'Mark all as read',
          }}
        /> */}
        {/* <Menu as='div' className='relative'>
          <Menu.Button className='flex items-center justify-center'>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='w-4 h-4'
            >
              <path
                d='M0 4H4V0H0V4ZM6 16H10V12H6V16ZM0 16H4V12H0V16ZM0 10H4V6H0V10ZM6 10H10V6H6V10ZM12 0V4H16V0H12ZM6 4H10V0H6V4ZM12 10H16V6H12V10ZM12 16H16V12H12V16Z'
                fill='#26282F'
              />
            </svg>
          </Menu.Button>
          <Menu.Items className='absolute right-2 z-10 mt-2 max-w-xs w-screen origin-top-right rounded shadow-xl ring-1 ring-black ring-opacity-5 flex flex-col bg-white py-3 drop-shadow'>
            {applications?.map((app) => (
              <Menu.Item
                key={app.code}
                as='div'
                className='px-3 py-2 hover:bg-sky-50 cursor-pointer'
                onClick={() => window.location.replace(app.url)}
              >
                <AppIcon code={app.code} />
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu> */}
        <Menu as='div' className='relative'>
          <Menu.Button
            name='nav-language'
            className='inline-flex w-full justify-center items-center md:gap-x-2 md:px-1 py-2 rounded-md hover:bg-sky-50 active:bg-sky-100'
          >
            <GlobeAltIcon className='h-6 w-6' />
            {!isMobile && <div data-testid='current-language'>{i18n.resolvedLanguage}</div>}
          </Menu.Button>
          {isMobile && (
            <Transition
              as={Fragment}
              enter='transition-opacity duration-80'
              enterFrom='opacity-0'
              enterTo='opacity-40'
              leave='transition-opacity duration-80'
              leaveFrom='opacity-40'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black opacity-40 z-10' onClick={() => close()} />
            </Transition>
          )}
          <Transition
            as={Fragment}
            enter={isMobile ? 'transition-transform duration-80 ease-out' : ''}
            enterFrom={isMobile ? 'transform translate-y-full' : ''}
            enterTo={isMobile ? 'transform translate-y-0' : ''}
            leave={isMobile ? 'transition-transform duration-80 ease-in' : ''}
            leaveFrom={isMobile ? 'transform translate-y-0' : ''}
            leaveTo={isMobile ? 'transform translate-y-full' : ''}
          >
            <Menu.Items
              className={
                isMobile
                  ? 'md:hidden fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg z-20 py-4 px-4 gap-y-1'
                  : 'absolute right-0 z-10 mt-2 py-4 px-4 gap-y-3 w-56 origin-top-right flex flex-col bg-white drop-shadow rounded shadow-xl ring-1 ring-black ring-opacity-5'
              }
            >
              {isMobile && (
                <div className='md:hidden w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
              )}
              {locales.map((locale) => (
                <Menu.Item key={`language-${locale.value}`} data-testid='nav-language'>
                  {() => (
                    <div
                      onClick={() => handleChangeLng(locale.value)}
                      className='cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0'
                      data-testid='nav-language'
                    >
                      {isMobile && (
                        <span className='mr-5'>
                          <span className='absolute'>
                            {i18n.language === locale.value ? <CheckIcon className='h-5 w-5' aria-hidden='true' /> : ''}
                          </span>
                        </span>
                      )}
                      {locale.title}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
        <Menu as='div' className='relative'>
          <Menu.Button className='flex items-center justify-center'>
            <ImgWrapper
              className='w-8 h-8 rounded-full border-sky-500 border'
              src={`${imageBase}/${user?.data.id}/76`}
              onErrorComponent={<p>{user?.data.firstName?.charAt(0)?.toUpperCase()}</p>}
            />
          </Menu.Button>
          {isMobile && (
            <Transition
              as={Fragment}
              enter='transition-opacity duration-80'
              enterFrom='opacity-0'
              enterTo='opacity-40'
              leave='transition-opacity duration-80'
              leaveFrom='opacity-40'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-black opacity-40 z-10' onClick={() => close()} />
            </Transition>
          )}
          <Transition
            as={Fragment}
            enter={isMobile ? 'transition-transform duration-80 ease-out' : ''}
            enterFrom={isMobile ? 'transform translate-y-full' : ''}
            enterTo={isMobile ? 'transform translate-y-0' : ''}
            leave={isMobile ? 'transition-transform duration-80 ease-in' : ''}
            leaveFrom={isMobile ? 'transform translate-y-0' : ''}
            leaveTo={isMobile ? 'transform translate-y-full' : ''}
          >
            <Menu.Items
              className={
                isMobile
                  ? 'md:hidden fixed bottom-0 left-0 w-full bg-white rounded-t-2xl shadow-lg z-20 py-4 px-4 gap-y-1'
                  : 'absolute right-0 z-10 mt-2 py-4 px-4 w-56 origin-top-right flex flex-col bg-white drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'
              }
            >
              {isMobile && (
                <div className='md:hidden w-16 h-2 bg-gray-300 rounded-full mx-auto mt-1 cursor-pointer'></div>
              )}
              <Menu.Item as='div'>
                <Link
                  to={`https://identity${
                    process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                  }.sistemiv.com`}
                >
                  <div className='pb-2 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0'>
                    {t('profile')}
                  </div>
                </Link>
              </Menu.Item>

              {isMobile && <div className='absolute md:hidden left-0 right-0 w-full h-px bg-gray-300'></div>}
              <Menu.Item>
                {() => (
                  <div
                    onClick={handleLogout}
                    className=' cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100 py-2 md:py-0'
                    data-testid='nav-profile-option'
                  >
                    {t('logout')}
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </NavigationBar>
  )
}

export default TopNav
