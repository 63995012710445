import React, { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Portal from '../pages/Portal'
import Logout from '../pages/Logout'
import ResponsiveContextProvider from '../contexts/ResponsiveContextProvider'

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <ResponsiveContextProvider>
        <Routes>
          <Route path='/' element={<Portal />} />
          <Route path='/:org' element={<Portal />} />
          <Route path='/logout' element={<Logout />} />
        </Routes>
      </ResponsiveContextProvider>
    </BrowserRouter>
  )
}

export default AppRouter
